
/* .logo-box {
    margin-top: 20px;
    margin-right: 20px;
    margin-left: -20px;
    height: 50%;
} */
 .App {
  text-align: center;
}

.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.link-button {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
}

.link-button:hover {
  background-color: #0056b3;
}
